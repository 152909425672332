import { Coupon } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getReferredFriendReward } from './getReferredFriendReward';
import { isReferredFriendEligible } from './isReferredFriendEligible';
import { RewardType, waitForReward } from './waitForReward';

interface CouponRewardResponse {
  isEligible: boolean;
  coupon: Coupon | undefined;
}

export async function waitForCouponReward(flowAPI: PlatformControllerFlowAPI): Promise<CouponRewardResponse> {
  let reward = await getReferredFriendReward({ flowAPI, rewardType: RewardType.COUPON }).catch(() => undefined);

  if (reward) {
    return {
      isEligible: true,
      coupon: reward.coupon,
    };
  }

  /* If the reward is not available immediately it means:
          1. Its new user and reward is still in creating phase
          2. User is not eligible (site owner, already site member)
     */
  const isEligible = await isReferredFriendEligible(flowAPI);
  if (!isEligible) {
    return {
      isEligible: false,
      coupon: undefined,
    };
  }

  // User is eligible so we need to wait for backend to create the coupon
  reward = await waitForReward({
    flowAPI,
    rewardType: RewardType.COUPON,
  });

  return {
    isEligible: true,
    coupon: reward?.coupon,
  };
}
