import { queryReferralRewards } from '@wix/ambassador-loyalty-referral-v1-referral-reward/http';
import { CouponStatusStatus as CouponStatus } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function hasUnusedCouponRewards(flowAPI: PlatformControllerFlowAPI): Promise<boolean> {
  const { httpClient } = flowAPI;
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return false;
  }

  try {
    const response = await httpClient.request(
      queryReferralRewards({
        contactId: 'me',
        query: {
          filter: {
            rewardType: 'CouponReward',
          },
        },
      }),
    );

    return response.data.referralRewards?.some(({ coupon }) => coupon?.status === CouponStatus.ACTIVE) ?? false;
  } catch (error) {
    if (error instanceof Error && error.message.includes('404')) {
      return false;
    }

    throw error;
  }
}
